import './App.css';
import Terminal from './components/Terminal/Terminal'
import Header from './components/Header/Header'

function App() {
  return (
    <div className="app">
      <Header />
      <Terminal />
    </div>
  );
}

export default App;
